import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import { Link } from 'components';
import { useIntersection } from 'hooks';

const TogetherIsBetter = () => {
  const [elRef, observerEntry] = useIntersection({ threshold: 0 });
  return (
    <Container>
      <div />
      <motion.div
        ref={elRef}
        initial={{ y: 100 }}
        animate={observerEntry.isIntersecting ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
        transition={{ duration: 0.5, delay: 0.25 }}>
        <StaticQuery
          query={graphql`
            {
              peopleInOfficeMobile: file(relativePath: { eq: "home/people_in_office_mobile.jpg" }) {
                name
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    width: 671
                    placeholder: BLURRED
                    formats: AUTO
                    quality: 90
                  )
                }
              }
              peopleInOfficeTablet: file(relativePath: { eq: "home/people_in_office_tablet.jpg" }) {
                name
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    width: 1343
                    placeholder: BLURRED
                    formats: AUTO
                    quality: 90
                  )
                }
              }
            }
          `}
          render={({ peopleInOfficeMobile, peopleInOfficeTablet }) => {
            const peopleInOfficeSources = withArtDirection(getImage(peopleInOfficeMobile), [
              {
                image: getImage(peopleInOfficeTablet),
                media: '(min-width: 48em)'
              }
            ]);
            return <PeopleInOffice image={peopleInOfficeSources} alt="people in office" />;
          }}
        />
        <h3>
          Healthcare Marketing
          <br /> at its best
        </h3>
        <p>
          Our services combine the latest in Healthcare Virtual & Hybrid events, Strategy &
          Activation, Digital & Social, and Brand Creative to successfully engage local and
          international audiences. We have extensive experience working within CRM systems such as Veeva and PromoMats.
        </p>
        <h3>
          Omnichannel. <br />
          We&apos;re Specialists.
        </h3>
        <p>
          We base our Strategy on Omnichannel methodologies. Learn how you can apply an Omnichannel
          approach for your campaigns here.
          <br />
          <Link
            to="/omnichannel-healthcare-campaigns"
            invertLight>
            Find Out More
          </Link>
        </p>
      </motion.div>
      <div />
    </Container>
  );
};

const Container = styled.section`
  background: #fff;
  grid-template-rows: 10.875rem 1fr 6.25rem;
  > div:last-of-type {
    background-color: var(--ax-beige);
  }
  > div:first-of-type {
    grid-area: 1 / 1 / 2 / -1;
    background-color: white;
  }
  > div:last-of-type {
    grid-area: 3 / 1 / -1 / -1;
  }
  > div:nth-of-type(2) {
    background: var(--ax-gold);
    box-shadow: 0.438rem 0.438rem 1.875rem rgba(0, 0, 0, 0.2);
    grid-area: 1 / 2 / -1 / 3;
    padding: 1.85rem 1.25rem 1.55rem;
    margin-bottom: 1.875rem;
    margin-top: -5rem;
    position: relative;
    h3:not(.big-background-section-title),
    p {
      position: relative;
      color: #fff;
      display: flex;
      flex-direction: column;
      gap: 1.875rem;
    }
    h3:not(.big-background-section-title) {
      font-family: 'Butler', serif;
      font-display: auto;
      font-size: 3rem;
      font-weight: 400;
      left: 0.021em;
      letter-spacing: 0.013em;
      line-height: 1.25em;
    }
    p {
      font-size: 1.25rem;
      left: 0.175em;
      letter-spacing: 0.001em;
      line-height: 1.6em;
      margin: 0.5em 0 2.925em;
      &:last-of-type {
        margin: 0.8em 0 0em;
      }
    }
  }
  @media screen and (min-width: 48rem) {
    grid-template-rows: 4.063rem 1fr 7.5rem;
    > div:nth-of-type(2) {
      grid-area: 1 / 1 / -1 / -1;
      margin: 0 1.25rem 1.875rem;
      margin-top: -5rem;
      padding: 3.25rem 2.2rem 3rem;
      p {
        margin: 1.75em 0 4.25em;
        &:last-of-type {
          margin: 1.75em 0 0em;
        }
      }
    }
  }
  @media screen and (min-width: 62.5rem) {
    > div:nth-of-type(2) {
      margin-bottom: 4.3rem;
    }
  }
  @media screen and (min-width: 76.875rem) {
    > div:nth-of-type(2) {
      display: grid;
      grid-template-rows: minmax(184px, auto) minmax(118px, auto);
      grid-gap: 32px 6.4%;
      justify-content: center;
      padding: 7.9rem 13.47%;
      margin: 0 3rem 4.3rem;
      margin-top: -5rem;
      h3:not(.big-background-section-title):first-of-type {
        grid-area: 1 / 1;
      }
      h3:not(.big-background-section-title):last-of-type {
        grid-area: 2 / 1;
      }
      p {
        margin: 0 !important;
      }
      p:first-of-type {
        grid-area: 1 / 2;
      }
      p:last-of-type {
        grid-area: 2 / 2;
      }
    }
  }
  @media screen and (min-width: 114.75em) {
    > div:nth-of-type(2) {
      max-width: 108.75rem;
      margin: 0 auto 4.3rem;
      margin-top: -5rem;
    }
  }
`;

const PeopleInOffice = styled(GatsbyImage)`
  height: 100%;
  left: 0;
  position: absolute !important;
  top: 0;
  width: 100%;
`;

const Button = styled(Link)`
  background: var(--ax-beige);
  border-radius: 2px;
  color: var(--ax-gold);
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.125rem;
  padding: 1.25rem 1.25rem;
  text-decoration: none;
  text-transform: uppercase;
  width: fit-content;
`;

export default TogetherIsBetter;
